<template>
  <div>
    <b-card no-body class="mb-0">
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <!-- <label>Show</label> -->
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>{{ $t("Show") }}</label>
          </b-col>

          <!-- Search -->
          <b-col cols="12" md="6">
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                :placeholder="$t('Search...')"
              />
            </div>
          </b-col>
        </b-row>
      </div>

      <b-table
        hover
        ref="refUserListTable"
        class="position-relative"
        :per-page="perPage"
        :current-page="currentPage"
        :items="filtered_activity_data"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        :empty-text="$t('No Matching Creates Found')"
        :sort-desc.sync="isSortDirDesc"
      >
        <template slot="my-link" slot-scope="data">
          <b-link :href="data.item.link">{{ $t("link") }}</b-link>
        </template>

        <!-- :variant="activity_name.color" -->
        <template #cell(activity_types)="data">
          <b-badge
            style="margin: 3px; padding-top: 5px; min-width: 50px"
            :variant="activity.color"
            v-for="activity in data.value"
            :key="activity.activity_type_name"
          >
            {{ activity.activity_type_name }}
          </b-badge>
        </template>

        <template #cell(name)="data">

            <span :style="`color:${data.item.activity_color};  margin :3px; padding-top:5px; min-width:50px`"> {{data.item.name}}</span>

        </template>

            <template #cell(is_active)="data">
             <b-badge  :variant="`${data.item.is_active ==1 ?'light-success':'light-danger'}`"> {{data.item.is_active ==1 ?$t("Active"):$t("Passive")}}</b-badge>

        </template>


        <template #cell(işlemler)="data">

          <template v-if="isMobile===true">
            <b-dropdown
              variant="link"
              no-caret
              :right="$store.state.appConfig.isRTL"
            >

              <template #button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="align-middle text-body"
                />
              </template>
              <b-dropdown-item :to="{ name: 'EditProduct', params: {productID: data.item.trainer_product_id} }">
                <feather-icon icon="EditIcon" />
                <span class="align-middle ml-50">{{$t("Edit")}}</span>
              </b-dropdown-item>


              <b-dropdown-item   @click="activityActiveChange(data.item.trainer_product_id,data.item.is_active)">
                <feather-icon icon="UserXIcon" />

                  <span class="align-middle ml-50">{{data.item.is_active==1? $t("Deactivate"):$t("Activate")}}</span>

              </b-dropdown-item>
            </b-dropdown>
          </template>

           <template v-if="isMobile===false">
             <b-button
                 size="sm"
                 class="mr-1"
                 variant="dark"
                 :to="{ name: 'productPriceList', params: { productID: data.item.trainer_product_id } }"
             >{{ $t("Price History") }}</b-button
             >

             <b-button
              size="sm"
              class="mr-1"
              variant="primary"
              :to="{ name: 'EditProduct', params: { productID: data.item.trainer_product_id } }"
              >{{ $t("Edit") }}</b-button
            >

            <b-button
              size="sm"
              :variant="data.item.is_active === false ? 'success' :'danger'"
              @click="activityActiveChange(data.item.trainer_product_id,data.item.is_active)"
              >{{ data.item.is_active === false ? $t("Activate")  : $t("Deactivate") }}</b-button

            >
          </template>

        </template>

        <!-- <template #cell(işlemler)="data">
          <b-button
            size="sm"
            class="mr-1"
            variant="outline-primary"
            :to="{
              name: 'activityEdit',
              params: { activityID: data.item.activity_id },
            }"
            >{{ $t("Edit") }}</b-button>
          <b-button
            size="sm"
            variant="outline-warning"
            :to="{
              name: 'activityEdit',
              params: { activityID: data.item.activity_id },
            }"
            >{{ $t("Deactivate") }}</b-button
          >
        </template> -->
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="6"
            sm=""
            class="
              d-flex
              align-items-left
              justify-content-right justify-content-sm-start
              radius 0
            "
          >
          <b-form-select v-model="selected" :options="options" @change="changeFiltering" class="select-config">
          </b-form-select>
          </b-col>
          <small class="mt-1">içerisindeki {{total_item}} kayıttan {{currentPage*1}}-{{currentPage*perPage}} arası ({{currentPage}}.sayfa) gösteriliyor </small>

          <b-col
            cols="6"
            sm=""
            class="
              d-flex
              align-items-right
              justify-content-right justify-content-sm-end
              radius 0
            "
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="total_item"
              :per-page="perPage"
              first-number
              last-number
              align="right"
              class="mb-0 mt-1 mt-sm-0 r-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BFormSelect,
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BBreadcrumb,
  BBreadcrumbItem,
  BFormCheckbox,
} from "bootstrap-vue";
import vSelect from "vue-select";
import DataBase from "@/services/DataBase.js";


export default {
  name: "activityList",
  components: {
    BFormSelect,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BBreadcrumb,
    BBreadcrumbItem,
    BFormCheckbox,
    vSelect
  },
  data() {
    return {
      companyBranchId: null,
      bgcolor: "primary",
      searchQuery: "",
      currentPage: null, //
      total_item: null,
      isSortDirDesc: true,
      sortBy: "id",
      search_query: "",
      perPage: 25,
      locale: this.$i18n.locale,
      perPageOptions: [10, 25, 50, 100],
      tableColumns: [
        { key: "title", label: this.$t("Product Title"), sortable: true },
        {
          key: "description",
          label: this.$t("Product Description"),
          sortable: true
        },
        // { key: "is_active", label: this.$t("Status"), sortable: false },
        { key: "işlemler", label: this.$t("Transactions"), sortable: false },
      ],
      productData: [],

      selected: 'tüm',
      options: [
          { text: this.$t('All Products'), value: 'tüm' },
          { text: this.$t('Active Products'), value: 'aktif' },
          { text: this.$t('Deactive Products'), value: 'deaktif' },
        ],
      trainer_id: null
    };
  },
  computed: {
    filtered_activity_data() {
      let data = [];
      this.productData.map((x) => {
        if (x.title.toUpperCase().includes(this.searchQuery.toUpperCase())) {
          data.push(x);
          this.total_item =data.length;
          this.currentPage=1;
        }
      });
      return data
    },
  },
  watch: {
    productData(val) {
      this.total_item = val.length;
    },
  },
  methods: {
    //eğitmenlerin aktiflik, deaktiflik durumlarına göre filtrelenebilmesi
    changeFiltering(arg) {
        if (arg === 'tüm') {
          this.productData = this.productBaseData;
        } else if (arg === 'aktif') {
          //aktif seçilirse base veriler içinde map et aktif true olanları tablo verisi olarak al
          let data = [];
          this.productBaseData.map((x) => {
            if (x.is_active === true) {
              data.push(x);
              this.total_items= data.length;
              this.currentPage = 1
            }
          });
          this.productData = data;
        } else if (arg === 'deaktif') {
          //deaktif seçilirse base veriler içinde map et aktif false olanları tablo verisi olarak al
          let data = [];
          this.productBaseData.map((x) => {
            if (x.is_active === false) {
              data.push(x);
              this.total_items = data.length;
              this.currentPage = 1
            }
          });
          this.productData = data;
      } else {
        //null gelebilir..
        this.productData = this.productBaseData;
      }
    },
    async getTrainerProductsData(){
      await this.$database.StudioService.get_trainer_product_list(
        this.$route.params.trainerID,
        this.companyBranchId
      ).then(res => {
         if (!res.is_success) return;
          this.productBaseData = res.result; //veri tabanında gelen ve hiç değişmeyecek veriler

          this.productData = res.result; // veri tabanından gelen verileri drapdonw option elementlerine yüklüyoruz
          this.total_product = res.result.length;
          this.currentPage = 1;
      })
    },
    activityActiveChange(trainer_product_id, is_active) {
      is_active = is_active == 0 ? "1" : is_active == "1" ? "0" : "1";

      this.$swal({
        title: this.$t("Are you sure?"),
        text: this.$t("Are You Sure You Want To Change The Product Activity Status?"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: this.$t("Yes"),
        cancelButtonText:  this.$t("No"),
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          DataBase.StudioService.product_isactive_change(
            trainer_product_id,
            is_active
          ).then((res) => {
            if (!res.is_success) {
              this.$functions.Messages.error('Activity status not changed');
              return;
            }
              this.$functions.Messages.success('Product Status Changed');
              this.getTrainerProductsData();
          });
        }
      });
    },
    getTrainerInfo(){
      this.$database.StudioService.get_trainer_info(
        this.$route.params.trainerID
      ).then(res => {productsSold
        this.trainer_id = res.result.trainer_id
      })
    }
  },
  created() {
    this.companyBranchId = this.$store.getters['auth/userInfo'].company_branch_id
    this.getTrainerProductsData();
  },
};
</script>

<!-- stillendirme -->
<style lang="scss" src="@/styles/scss/instructorProducts.scss" scoped> </style>
